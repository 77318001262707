<template>
  <div class="policy" v-if="!isProcessing">
    <h2 class="policy__subtitle">個人情報保護方針</h2>
    <p class="policy__paragraph">
      {{ companyName }}は、月額課金サービス「{{ serviceName }}」（以下、「本サービス」といいます。）の運営に必要不可欠である個人情報の活用において、個人情報の適切な保護の重要性と社会的責任を十分に認識し、個人情報保護に関する法律、その他関係法令を遵守いたします。
    </p>
    <h2 class="policy__subtitle">個人情報の定義</h2>
    <p class="policy__paragraph">
      個人情報とは、ユーザー個人に関する情報であって、当該情報を構成する氏名、住所、電話番号、メールアドレス、その他の記述等により当該ユーザーを識別できるものをいいます。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的にユーザー個人を識別できるものも個人情報に含まれます。
    </p>
    <h2 class="policy__subtitle">個人情報の取得方法</h2>
    <p class="policy__paragraph">
      {{ companyName }}は、ユーザーが会員登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、銀行口座番号、クレジットカード番号などの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、{{ companyName }}の提携先などから取得することがあります。
    </p>
    <h2 class="policy__subtitle">個人情報の利用目的</h2>
    <p class="policy__paragraph">
      {{ companyName }}が個人情報を収集・利用する目的は、以下のとおりです。
      <ol class="policy__paragraph__list">
        <li>本サービスのサービス提供・運営のため。</li>
        <li>本サービスの会員登録・ログイン時における本人確認のため。</li>
        <li>ユーザーに会費及び利用料金を請求するため。</li>
        <li>{{ companyName }}が申込者の入会審査に関する手続に協力するため。</li>
        <li>利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーを特定し、ご利用をお断りするため</li>
        <li>ユーザーに自身の登録情報の閲覧や変更、削除を行っていただくため。</li>
        <li>本サービスのサービスに関する、電話・メール・SNS等による案内および連絡のため。</li>
        <li>本サービスで開催されるイベント等にご協力いただいた方への報告のため。</li>
        <li>上記の利用目的に付随する目的のため。</li>
        {{ companyName }}が個人情報のうち本項に定める目的に必要な範囲の情報を、{{ companyName }}及び{{ companyName }}が指定する第三者に対し提供する場合があります。
      </ol>
    </p>
    <h2 class="policy__subtitle">個人情報の開示</h2>
    <p class="policy__paragraph">
      {{ companyName }}は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあります。
      <ol class="policy__paragraph__list">
        <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
        <li>{{ companyName }}の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
        <li>その他法令に違反することとなる場合</li>
      </ol>
    </p>
    <h2 class="policy__subtitle">個人情報の訂正および削除</h2>
    <p class="policy__paragraph">
      <ol class="policy__paragraph__list">
        <li>ユーザーは、{{ companyName }}の保有する自己の個人情報が誤った情報である場合には、{{ companyName }}が定める手続きにより、{{ companyName }}に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。</li>
        <li>{{ companyName }}は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。</li>
        <li>{{ companyName }}は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。</li>
      </ol>
    </p>
    <h2 class="policy__subtitle">個人情報の利用停止等</h2>
    <p class="policy__paragraph">
      <ol class="policy__paragraph__list">
        <li>{{ companyName }}は、本人から個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。</li>
        <li>前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。</li>
        <li>{{ companyName }}は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。</li>
        <li>前二項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。</li>
      </ol>
    </p>
    <h2 class="policy__subtitle">プライバシーポリシーの変更</h2>
    <p class="policy__paragraph">
      <ol class="policy__paragraph__list">
        <li>本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。</li>
        <li>{{ companyName }}が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。</li>
      </ol>
    </p>
    <h2 class="policy__subtitle">個人情報の取扱いに関する相談や苦情の連絡先</h2>
    <p class="policy__paragraph">
      本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
    </p>
    <p class="policy__paragraph">
      運営：{{ companyName }}<br>
      お問い合わせ先：
      <a class="menu__list__item__text" target="_blank" rel="noopener noreferrer" v-if="isContactURL" :href="contact">{{ contact }}</a>
      <a class="menu__list__item__text" target="_blank" v-else
         :href="'mailto:' + contact + '?subject=' + this.serviceName + 'に関するお問い合わせ'">{{ contact }}</a>
    </p>
  </div>
</template>
<script>
import url from '@/assets/lib/url'

export default {
  mixins: [url],
  name: 'policy',
  async mounted () {
    const promises = []

    // 設定の基本情報が存在しない場合は取得
    const settingKey = ['companyName', 'serviceName', 'contact']
    for (const key of settingKey) {
      if (!this[key]) promises.push(this.$store.dispatch('settingGeneral/getSettingGeneral', key))
    }

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} 運営の名前
     */
    companyName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyName')
      return data ? data.value : null
    },
    /**
     * @return {String} サービスの名前
     */
    serviceName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('serviceName')
      return data ? data.value : null
    },
    /**
     * @return {String} お問い合わせ先（メールアドレス or URL）
     */
    contact () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('contact')
      return data ? data.value : null
    },
    /**
     * @return {Boolean} お問い合わせ先がURLかどうか
     */
    isContactURL () {
      return this.isValidURL(this.contact)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.policy {
  padding: $padding_height $padding_width 100px;
  margin-top: $header_height;
  font-size: 1.1rem;
  &__subtitle {
    margin: 0;
  }
  &__paragraph {
    margin: 5px;
    &__list {
      margin: 2px 0;
    }
  }
}
</style>
